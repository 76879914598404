
import { defineComponent, onMounted, reactive, ref } from "vue";
import { getManager } from "@/core/services/api/manager";
import { addManagerNotifySetting } from "@/core/services/api/managerNotifySetting";
import localstorage from "@/core/services/LocalstorageService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  setup(props, { emit }) {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const formRef = ref<null | HTMLFormElement>(null);
    const form = reactive({
      manager: "",
      functionType: "FacilityFeedback",
      channel: [],
    });
    const rules = reactive({
      manager: [
        {
          required: true,
          message: "請選擇管理者",
          trigger: "change",
        },
      ],
      functionType: [
        {
          required: true,
          message: "請選擇通知類別",
          trigger: "change",
        },
      ],
      channel: [
        {
          required: true,
          message: "請選擇通知方式",
          trigger: "change",
        },
      ],
    });
    const managerOption: any = reactive([]);
    const functionTypeOption = [
      {
        text: "使用評價",
        value: "FacilityFeedback",
      },
    ];
    const reseatForm = () => {
      formRef.value!.resetFields();
      Object.assign(form, {
        manager: "",
        functionType: "FacilityFeedback",
        channel: [],
      });
    };

    // 取得空間管理者
    const handleGetManager = async () => {
      return await getManager(currentSpaceGuid!);
    };

    // 設置管理者選單
    const setManagerOption = async () => {
      let managers: any = await handleGetManager();
      managerOption.splice(0);
      for (const manager of managers) {
        managerOption.push({
          text: `${manager.simpleUser.name} (${manager.simpleUser.email})`,
          value: manager.simpleUser.guid,
        });
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "您確定要新增嗎?",
            confirmButtonText: "確定",
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              let request = [
                {
                  functionType: form.functionType,
                  user: form.manager,
                  channel: form.channel.join(","),
                },
              ];
              await addManagerNotifySetting(currentSpaceGuid, request);
              await Swal.fire("新增成功!", "", "success");
              emit("onAdd");
              reseatForm();
            }
          });
        }
      });
    };

    onMounted(() => {
      let addManagerNotifySettingModal: any = document.getElementById(
        "addManagerNotifySetting-modal"
      );
      addManagerNotifySettingModal.addEventListener(
        "hidden.bs.modal",
        function () {
          reseatForm();
        }
      );
    });

    const init = async () => {
      await setManagerOption();
    };
    init();

    return {
      formRef,
      form,
      rules,
      managerOption,
      functionTypeOption,
      submit,
    };
  },
});
