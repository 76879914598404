
<template>
  <div class="card p-5" style="height: 1000px">
    <div class="card-header border-0">
      <div class="card-toolbar m-0">
        <ul
          class="
            nav nav-stretch
            fs-5
            fw-bold
            nav-line-tabs nav-line-tabs-2x
            border-transparent
          "
          role="tablist"
        >
          <!-- 使用評價 -->
          <li class="nav-item" role="presentation">
            <a
              id="kt_referrals_year_tab"
              class="nav-link text-active-primary active"
              data-bs-toggle="tab"
              role="tab"
              href="#kt_customer_details_invoices_1"
            >
              使用評價
            </a>
          </li>
        </ul>
      </div>
      <div class="d-flex align-items-center">
        <button
          data-bs-toggle="modal"
          data-bs-target="#addManagerNotifySetting-modal"
          type="button"
          class="btn btn-primary"
        >
          新增
        </button>
      </div>
    </div>

    <div class="card-body pt-0">
      <div id="kt_referred_users_tab_content" class="tab-content">
        <div
          id="kt_customer_details_invoices_1"
          class="py-0 tab-pane fade active show"
          role="tabpanel"
        >
          <Datatable
            :table-data="notifySetting.facilityFeedback"
            :table-header="tableHeader"
            :enable-items-per-page-dropdown="false"
            :openTextEnd="false"
            :striped="true"
          >
            <template v-slot:cell-userName="{ row: tableData }">
              {{ tableData.userName }}
            </template>
            <template v-slot:cell-channel="{ row: tableData }">
              <el-checkbox-group
                v-if="tableData.isEdit"
                v-model="currentChannel"
              >
                <el-checkbox label="Email">電子信箱</el-checkbox>
                <el-checkbox label="Fcm">推撥通知</el-checkbox>
              </el-checkbox-group>
              <template
                v-else
                v-for="channel in tableData.channel"
                :key="channel"
              >
                <span class="badge bg-primary me-2">{{
                  noticeMap[channel]
                }}</span>
              </template>

              <el-button
                v-if="!tableData.isEdit"
                @click="setIsEdit(tableData, true)"
                type="text"
                >修改</el-button
              >
              <el-button
                v-if="tableData.isEdit"
                @click="sendOut(tableData)"
                type="text"
                style="color: #e6a23c"
                >送出</el-button
              >
              <el-button
                v-if="tableData.isEdit"
                @click="setIsEdit(tableData, false)"
                type="text"
                style="color: #f56c6c"
                >取消</el-button
              >
            </template>
          </Datatable>
        </div>
      </div>
    </div>
  </div>
  <!-- 新增管理者通知設定modal -->
  <div
    class="modal fade"
    id="addManagerNotifySetting-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
            id="addManagerNotifySetting-exampleModalLabel"
          >
            新增管理者通知設定
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <AddManagerNotifySetting @onAdd="onAdd()"></AddManagerNotifySetting>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref } from "vue";
import {
  getManagerNotifySetting,
  updateManagerNotifySetting,
  deleteManagerNotifySetting,
} from "@/core/services/api/managerNotifySetting";
import { getManager } from "@/core/services/api/manager";
import localstorage from "@/core/services/LocalstorageService";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import AddManagerNotifySetting from "./components/AddManagerNotifySetting.vue";
import { Modal } from "bootstrap";

export default defineComponent({
  components: {
    Datatable,
    AddManagerNotifySetting,
  },
  setup() {
    let addManagerNotifySettingModal;
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const checkList = ref([]);
    const noticeMap = {
      Email: "電子郵件",
      Fcm: "推撥通知",
    };
    const tableHeader = ref([
      { key: "userName", name: "管理者名稱" },
      { key: "channel", name: "通知方式" },
    ]);
    const notifySetting: any = reactive({
      facilityFeedback: [],
    });
    const facilityFeedback: any = reactive([]);
    const managers: any = reactive([]);
    const currentChannel: any = ref([]);
    const currentFunctionType = ref("FacilityFeedback");

    const toKeep = () => {
      console.log("checkList", checkList.value);
    };

    // 取得管理者通知設定
    const handleGetManagerNotifySetting = async () => {
      return await getManagerNotifySetting(currentSpaceGuid);
    };

    // 取得空間管理者
    const setManager = async () => {
      let responses: any = await getManager(currentSpaceGuid!);
      managers.splice(0, managers.length, ...responses);
    };

    // 根據guid取得管理員
    const getManagerByGuid = (guid) => {
      let filterManager = managers.filter(
        (manager) => manager.simpleUser.guid == guid
      );
      return filterManager[0].simpleUser;
    };

    // 取得列表資料
    const getTableData = (list) => {
      let tableData: any = [];
      for (const item of list) {
        tableData.push({
          guid: item.guid,
          user: item.user,
          userName: getManagerByGuid(item.user).name,
          channel: item.channel.replace(/ /g, "").split(","),
          isEdit: false,
          functionType: item.functionType,
        });
      }
      return tableData;
    };

    // 設置項目設定列表
    const setItemList = async () => {
      let managerNotifySetting: any = await handleGetManagerNotifySetting();
      // 取出使用評價通知設定
      let filterFacilityFeedback = managerNotifySetting.filter(
        (setting) => setting.functionType == "FacilityFeedback"
      );
      notifySetting.facilityFeedback.splice(
        0,
        notifySetting.facilityFeedback.length,
        ...getTableData(filterFacilityFeedback)
      );
      facilityFeedback.splice(
        0,
        facilityFeedback.length,
        ...getTableData(filterFacilityFeedback)
      );
    };

    // 設置編輯狀態
    const setIsEdit = (item, status) => {
      if (status) {
        for (const o of notifySetting.facilityFeedback) {
          if (o.guid == item.guid) {
            o.isEdit = true;
          } else {
            o.isEdit = false;
          }
          currentChannel.value.splice(
            0,
            currentChannel.value.length,
            ...item.channel
          );
        }
      } else {
        let filterSettings = notifySetting.facilityFeedback.filter(
          (o) => o.guid == item.guid
        );
        filterSettings[0].isEdit = false;
      }
    };

    // 更新管理通知方式
    const sendOut = async (item) => {
      console.log("currentChannel.value", currentChannel.value);
      let request = {
        functionType: item.functionType,
        user: item.user,
        channel: currentChannel.value.join(","),
      };
      if (currentChannel.value.length == 0) {
        await Swal.fire({
          title: "將在列表中刪除",
          text: "您已取消所有通知方式，確定後管理者將從此列表中移除。",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "確定",
          cancelButtonText: "取消",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await deleteManagerNotifySetting(currentSpaceGuid, item.guid);
            await Swal.fire("更新成功!", "", "success");
          }
        });
      } else {
        await updateManagerNotifySetting(currentSpaceGuid, item.guid, request);
        await Swal.fire("更新成功!", "", "success");
      }

      await setItemList();
    };

    const onAdd = async () => {
      addManagerNotifySettingModal.hide();
      await setItemList();
    };

    onMounted(() => {
      addManagerNotifySettingModal = new Modal(
        document.getElementById("addManagerNotifySetting-modal")
      );
    });

    const init = async () => {
      await setManager();
      await setItemList();
    };
    init();

    return {
      checkList,
      noticeMap,
      toKeep,
      tableHeader,
      notifySetting,
      facilityFeedback,
      setIsEdit,
      currentChannel,
      sendOut,
      onAdd,
    };
  },
});
</script>
