<template>
  <el-form
    @submit.prevent="submit()"
    :model="form"
    :rules="rules"
    ref="formRef"
    class="form"
  >
    <div>
      <label class="fs-6 fw-bold mb-2">
        <span class="required">管理者</span>
      </label>
      <el-form-item prop="manager" style="width: 100%">
        <el-select
          style="width: 100%"
          v-model="form.manager"
          filterable
          placeholder="請選擇管理者"
        >
          <el-option
            v-for="item in managerOption"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <label class="fs-6 fw-bold mb-2">
        <span class="required">通知類別</span>
      </label>
      <el-form-item style="width: 100%" prop="functionType">
        <el-select
          style="width: 100%"
          v-model="form.functionType"
          placeholder="請選擇通知類別"
        >
          <el-option
            v-for="item in functionTypeOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <label class="fs-6 fw-bold mb-2">
        <span class="required">通知方式</span>
      </label>
      <el-form-item prop="channel">
        <el-checkbox-group v-model="form.channel">
          <el-checkbox label="Email">電子信箱</el-checkbox>
          <el-checkbox label="Fcm">推撥通知</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn btn-success" type="submit">送出</button>
    </div>
  </el-form>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { getManager } from "@/core/services/api/manager";
import { addManagerNotifySetting } from "@/core/services/api/managerNotifySetting";
import localstorage from "@/core/services/LocalstorageService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  setup(props, { emit }) {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const formRef = ref<null | HTMLFormElement>(null);
    const form = reactive({
      manager: "",
      functionType: "FacilityFeedback",
      channel: [],
    });
    const rules = reactive({
      manager: [
        {
          required: true,
          message: "請選擇管理者",
          trigger: "change",
        },
      ],
      functionType: [
        {
          required: true,
          message: "請選擇通知類別",
          trigger: "change",
        },
      ],
      channel: [
        {
          required: true,
          message: "請選擇通知方式",
          trigger: "change",
        },
      ],
    });
    const managerOption: any = reactive([]);
    const functionTypeOption = [
      {
        text: "使用評價",
        value: "FacilityFeedback",
      },
    ];
    const reseatForm = () => {
      formRef.value!.resetFields();
      Object.assign(form, {
        manager: "",
        functionType: "FacilityFeedback",
        channel: [],
      });
    };

    // 取得空間管理者
    const handleGetManager = async () => {
      return await getManager(currentSpaceGuid!);
    };

    // 設置管理者選單
    const setManagerOption = async () => {
      let managers: any = await handleGetManager();
      managerOption.splice(0);
      for (const manager of managers) {
        managerOption.push({
          text: `${manager.simpleUser.name} (${manager.simpleUser.email})`,
          value: manager.simpleUser.guid,
        });
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "您確定要新增嗎?",
            confirmButtonText: "確定",
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              let request = [
                {
                  functionType: form.functionType,
                  user: form.manager,
                  channel: form.channel.join(","),
                },
              ];
              await addManagerNotifySetting(currentSpaceGuid, request);
              await Swal.fire("新增成功!", "", "success");
              emit("onAdd");
              reseatForm();
            }
          });
        }
      });
    };

    onMounted(() => {
      let addManagerNotifySettingModal: any = document.getElementById(
        "addManagerNotifySetting-modal"
      );
      addManagerNotifySettingModal.addEventListener(
        "hidden.bs.modal",
        function () {
          reseatForm();
        }
      );
    });

    const init = async () => {
      await setManagerOption();
    };
    init();

    return {
      formRef,
      form,
      rules,
      managerOption,
      functionTypeOption,
      submit,
    };
  },
});
</script>

<style>
</style>