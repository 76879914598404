import ApiService from "@/core/services/ApiService";

// 取得管理者通知設定
export function getManagerNotifySetting(spaceGuid) {
    return new Promise((resolve, reject) => {
        ApiService.get(`Space/${spaceGuid}/ManagerNotifySetting`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 更新管理者通知設定
export function updateManagerNotifySetting(spaceGuid,managerNotifySettingGuid,request) {
    return new Promise((resolve, reject) => {
        ApiService.patch(`Space/${spaceGuid}/ManagerNotifySetting/${managerNotifySettingGuid}`,request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 新增管理者通知設定
export function addManagerNotifySetting(spaceGuid,request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/ManagerNotifySetting`,request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 刪除管理者通知設定
export function deleteManagerNotifySetting(spaceGuid,managerNotifySettingGuid) {
    return new Promise((resolve, reject) => {
        ApiService.delete(`Space/${spaceGuid}/ManagerNotifySetting/${managerNotifySettingGuid}`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}