import ApiService from "@/core/services/ApiService";

// 取得空間中的管理者
export function getManager(spaceGuid) {
    return new Promise((resolve, reject) => {
        ApiService.get(`Space/${spaceGuid}/Managers`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}